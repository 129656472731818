import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { EnvovadreComponent } from './envovadre/envovadre.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  /* {
    path: 'posts',
    loadChildren: () => import('./post/post.module').then(m => m.PostModule)
  }, */
  {
    path: 'releases',
    loadChildren: () => import('./releases/releases.module').then(m => m.ReleasesModule)
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'envovadre',
    component: EnvovadreComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<h1>Crisis</h1>
<div class="crisis-container">
    <div class="image">
        <img src="/assets/img/cover_crisis.jpeg" alt="">
        <p>Artwork by: <a href="https://www.facebook.com/r.x.a.art/" target="_blank">Roxanne Art</a></p>
    </div>
    <p>I could start these lines as everyone who is releasing new music nowadays does: 2020 was full of shit, and we lost and were deprived of a lot of things, we couldn't even do parties, and I could go on and whine about these for so long. Yeah, of course, it was (and it still is) shitty but I think most of us were able to adapt in some form.</p>
    <p>For me, the last year was all about surviving and adapting to the new situation and the aftermaths, which just came one after another, and most of the time this fuckn' virus was the last thing on my list to worry about.</p>
    <p>My whole life turned upside down in just 2 months, and a half year later I realized that I'm nowhere near to calling the circumstances stable... I just collapsed into total depression.</p>
    <p>At first, it wasn't too striking, since I can be pretty sarcastic anyway. But after 3 days I started to act furiously towards anyone who tried to start a conversation with me. Thankfully the weekend came and I was able to think more clearly, and started to do a little self-investigation about what went wrong, and when I found out that I couldn't do any better and still I'm in this fucked up condition I just started to laugh at myself.</p>
    <p>Then I looked over my flat, and I had this spontaneous idea; maybe if I can't fix the current situation at the moment, it's the perfect time to go over the demos I started last year and just do a little reorganization.</p>
    <p>I started with this track which was the closest to call as 'release ready' after I saved this with 3 other tracks to a different folder, I deleted everything that I made in the past year (most of them were just basic drum tracks or empty projects), and reopened this track, and finished it over the weekend.</p>
    <p>And you know what? It worked. It worked because it didn't matter who was going to like this music, I finished something, and that was a valuable thing to me.</p>
    <p>When all of this pandemic started with home offices and all of shit, a lot of news site started to whining about that this is the perfect time to learn something new, to do something new. Bullshit. Being a creative, or doing something creative doesn't mean that you'll have any inspiration out of the box. And while I can encourage you to start learning something (coding, drawing, music, whatever), if you're feeling down, or depressed, most of the time you can't progress in anything.</p>
    <p>Start with something small, a little change to your surroundings can help, just clean the house, get rid of what's not needed anymore, etc.</p>
    <p>When you're able to finish something, you can call that moment a victory, and you can build your next step on that very moment. Step by step.</p>
    <p>This track waited for the final touches for more than a half year, and it's feels fucking great that I'm able to present it you.</p>
    <p><a class="bc_link" href="https://envo.bandcamp.com">Check it out on bandcamp</a></p>
    <p>Or you can download for free from the <a href="/releases">Releases</a> menu.</p>

</div>


<!-- <ul>
    <li *ngFor="let post of posts; index as i" >
        {{post.title}}
    </li>
</ul> -->
import { Component, OnInit } from '@angular/core';
import { Post } from '../http/post';

@Component({
  selector: 'app-allpost',
  templateUrl: './allpost.component.html',
  styleUrls: ['./allpost.component.scss']
})
export class AllpostComponent implements OnInit {

  posts: Post[] = [];

  constructor(/* private postsService: PostsService */) { }

  ngOnInit() {
    //this.postsService.getAll().subscribe(p => this.posts = p);
    console.log(this.posts);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostsRoutingModule } from './post-routing.module';
import { AllpostComponent } from './allpost/allpost.component';



@NgModule({
  declarations: [AllpostComponent],
  imports: [
    CommonModule,
    PostsRoutingModule
  ]
})
export class PostModule { }

<div class="envo_vadre">
  <h1>Envo vs. Vadre</h1>
  <h2>The whole story so far.</h2>
  
  <p>Sziasztok! Tekintve, hogy kezdenek itt elfajulni a dolgok, gondoltam talán segíthet a helyzet megértésében, ha esetleg az én oldalamról is végigvezetném, hogy miért tartunk ott, ahol. Teszem mindezt azért is, mert bár közel sem vagyok makulátlanul tiszta a sztoriban (követtem el hibákat én is) de az én álláspontom maximálisan védhető.</p>
  
  <p>Kezdhetném ezt a történetet nagyon régről, hogy hogyan működött közre a Kinkster és a Freedom Fetish a múltban, és hogy ennek milyen gyümölcsei voltak, de ezt hagyjuk is, rá fogok térni, amikor ennek helye lesz, de nagy általánosságban egészen addig nem voltak komoly nézeteltérések, amíg a MOF ilyen felemás módon meg nem nyitotta kapuit.</p>
  
  <p>Ahogy a MOF megnyílt, feltűnhetett számotokra, hogy kb az egyetlen fix DJ én voltam egészen április végéig, amíg nem ki nem derült, hogy Vadre kérdés nélkül rászervezett az akkor már rég lefoglalt, de még meg nem hirdetett 4. évfordulós Kinksterre, ami történetesen május 12-re esett. Ez egy pénteki nap volt, hisz februártól kezdve egészen májusig, a súrlódások minimalizálása érdekében a Kinkster szándékosan kerülte a szombati időpontokat. Ettől függetlenül is érezhető volt a látogatók számának csökkenése, de az elején ezt igyekeztem betudni más körülményeknek is, és próbáltam nyugtatni saját magamat is azzal, hogy hosszú távon mi is csak nyerünk a klubbal. </p>
    
  <p>Április végén világossá vált számomra, hogy itt semmiféle értelmes partneri viszonyra nincs esély, hanem csak egy konkurenciát kaptunk a nyakunkba, amit történetesen még én is erősítek azzal, hogy félig-meddig a jelenlétemet adom hozzá (ha már a nevemet nem is, de erre később még visszatérünk).</p>
  
  <p>Mindaz az értetlenség, amit Vadre tanúsított felém csak megerősítette a korábbi benyomásaimat, hogy teljesen feleslegesen koptatom a billentyűzetet, nem megy át az üzenet, hogy mégis mi a problémám a kialakult szituációval. De nem ezt a hozzáállást vártam volna el sem egy baráti, sem egy partneri kapcsolattól se. A minimum elvárható szint az lett volna ugyanis, hogy mondjuk megkérdeznek minket, hogy mikorra tervezzük a májusi bulit. Még akkor is, ha szerintük nincs közönségbeli átfedés.</p>
  
  <p>Hosszas és teljesen felesleges chatelés után én mérgemben lemondtam azt az aznap esti bulit, amin még annyival se voltam megtisztelve, hogy egyedüli DJ-ként legalább a nevem ki legyen írva. Hozzáteszem, valószínűleg nem döntök így, ha egyébként minden más rendben ment volna, de sokadik nekifutásra sem sikerült az az ugyancsak minimálisan elvárható szint, hogy legalább azt tudjam, hogy az adott szombaton mettől-meddig van igény a munkámra, vagy hogy egyébként még műsor is lesz-e X-től Y óráig, ahol én addig csak az időmet tölteném el üresen. Ugyanígy az a ki nem mondott elvárás sem segített a helyzeten, hogy én alapjáraton ingyen és bérmentve szolgáltassak felszerelést (DJ pult).</p>
  
  <p>Egyébként utóbbit szívesen tettem a Freedom Fetish számára, mert ott abszolút azt éreztem, hogy a közös ügyünkért teszek, és mindannyian profitálunk belőle, de ahogy a DJ pulttal probléma lett, és az már kimondva is csak az én problémám volt, egy kicsit megváltozott arról a véleményem, hogy mibe és mennyit adok bele önzetlenül a jövőben akár más produkcióknál is.</p>
  
  <p>Szeretném hozzátenni, hogy olyat egészen áprilisig még soha nem csináltam a közel 13 éves zenei utam során, hogy aznap, vagy pár órával korábban lemondjak egy rendezvényt. Én az a kategória vagyok, aki még egy középfül-gyulladással félig siketen is lehozza a bulit, ha úgy hozza a sors, szóval gondolhatjátok, hogy mennyire szakadt el a cérna.</p>

  <p>Én ezt követően nagyjából 1 héttel később küldtem neki egy SMS-t, hogy ugyan még mindig mérhetetlenül haragszok, és hogy csalódott vagyok, de a stílusomért bocsánatot kérek, és nyílt vagyok újra tárgyalni.</p>
  
  <p>Pár héttel később Vadre felhívott, hogy talán már lehiggadtak annyira a kedélyek, hogy leüljünk közösen beszélgetni, és talán ő is jól érti az én problémámat, úgyhogy adjunk ennek egy esélyt. Ezen a megbeszélésen Vadre két "kedvezménnyel" készült a Kinkster számára: Az egyik az volt, hogy egyedüliként megkaphatjuk havonta egyszer a klubot szombatra. A másik pedig az, hogy ha mi szombatra szerveznénk bulit, akkor majd Ők viszik el a pénteket. Gondolom, hogy ez utóbbit nem kell részleteznem, hogy miért nevetséges kedvezmény a részünkre, hisz valójában nem az. Az előbbi viszont némi magyarázatra szorulhat, ezt a lábjegyzetben megtalálhatjátok alul.</p>
  
  <p>Mindezen "jó fej" és "gáláns" ajánlatok mellett Vadre elkötelezett volt, hogy vele nem lehet azt megcsinálni, hogy Őt csak úgy lemondják aznap, úgyhogy úgy gondolta, hogy velem fog példát statuálni, és kirakatott Kinkával a júniusi FF programjából. Én ott és akkor elengedtem azt, hogy ennek a békülési folyamatnak bármi eredménye is legyen.</p>

  <p>Az is egy gyönyörű momentum volt, amikor Vadre úgy gondolta, hogy mindezek ellenére szent a béke, minden rendben, és innentől kezdve jóban vagyunk. Pont az előtt a Kinksteres megbeszélés előtt oszlattam el a kétségeit ezzel kapcsolatban, amikor egyébként a Pride hétvége kapcsán is egyeztettünk a csapattal. Felhívott, hogy szerinte valaki fel akarja forgatni ezt a szent békét, én pedig biztosítottam afelől hogy ilyenről szó sincs, és teljesen kár itt bárki másban keresni a hibát saját magán kívül...</p>
  
  <p>Ezt követően Vadre azon háborodott fel, hogy csodák csodájára nem mentünk bele a közös Pride hétvégének az ötletébe, és nem voltunk hajlandóak közös promóciót se csinálni. Miért is tettük volna, hisz se rövid, se közép, se hosszútávon nem szolgált volna minket semmilyen promó, ami azt a konkurenciát erősíti, amelyik egyébként tökéletesen semmibe vesz majd minket a jövőben is, és ami folyamatosan problémát fog nekünk okozni.</p>
    
  <p>Mint ahogy egyébként semmibe, úgy ebbe sem sikerült Balázsnak belenyugodnia, és mindenképp bele kellett valahogy fűznie a Kinkstert a saját MOF-os programajánlójába, ami után én megkértem, hogy a jövőben ettől teljesen tartózkodjon, és hozzátettem, hogy kitiltani nem fogom, de egyébként szerintem nem túl etikus eljönnie egy olyan buliba, amit pont az Ő üzleti tevékenysége tesz tönkre aktívan. És egyébként pontosan ennek szellemében dobtam ki a Kinkster saját facebook csoportjából még május elején. Ez lehet, hogy túlzás volt, de én akkor úgy gondoltam, hogy pont a fentebb leírtak miatt, neki semmi keresnivalója nincs a Kinkster közelében.</p>
  
  <p>Természetesen itt is lesújtva érezte magát, hogy hogyan írhatok ilyesmit, és miért nem tudom elfogadni, hogy ő csak támogatni akarja a Kinkstert.</p>
    
  <p>Erről az ominózus beszélgetésről nem tudott senki sem a Kinksteren belül, egyedül egy szervezőtársamnak mutattam meg a teljes beszélgetést buli előtt pár órával, mert kíváncsi voltam a véleményére, de neki sem adtam semmilyen instrukciót azzal kapcsolatban, hogy Vadre ki lenne tiltva, vagy hogy csak engedéllyel léphetne be a klubba. Épp ezért voltam meglepve, amikor Ildi odajött hozzám a DJ pulthoz megkérdezni, hogy bejöhet-e Vadre, vagy sem. (Erről még videó is van, ugyanis pont teszteltem, hogy mennyire vállalható, ha a DJ pult belső részét kamerával vesszük fel, későbbi promó szempontjából úgy, hogy a közönség egyáltalán nem látszik.) Az, ahogy az utólagos elmondások alapján Vadre az embereimmel beszélt, arra alsó hangon is csak a tiszteletlen jelző illik rá, márpedig ezek után teljes joggal járhatna a végleges kitiltás is.</p>
  
  <p>És eljutottunk a jelenig. A fentiek alapján mindenki szűrje le, amit akar. Ahogy az elején is említettem, én sem vagyok patyolat tiszta a sztoriban, és nekem is voltak olyan lépéseim, amikre nem vagyok büszke, de ha felém van kritika, én boldogan meghallgatom őket, és igyekszem tanulni belőlük.</p>

  <hr>

  <h2>Lábjegyzetek</h2>

  <p>Felmerülhet bennetek pár tök jogos kérdés, mint például hogy:</p>

  <h3>Mi a gond a MOF-fal, és miért nem tudna működni ott egy Kinkster?</h3>
  <p>Két hatalmas probléma van a MOF-fal, annak ellenére, hogy a környezeti specifikációkat tekintve egy tök király hely is lehetne. Az egyik a nyitvatartási idő. Stikában elketyeg a klub hajnal kettőig, de valójában éjfélig van nyitvatartási engedélye. A másik pedig a hangerőkorlát, ami éjfél után még az egyébként is nevetséges szintnél is rosszabb lesz. A Kinkster nem csak egy fétis parti, hanem egy rave/techno parti is, az Edith-es hajnal 4-es zárás is túl korán van valójában. Ilyen téren a MOF nekünk nem alkalmas.</p>
  <p>Mi nagyon vártuk egyébként, hogy megnyisson a hely. Személy szerint én nagy reményeket fűztem hozzá, ehhez képest sajnos csalódás lett a vége.</p>
  <p>Az induláskor tanúsított bizalmamat egyébként mi sem bizonyítja jobban annál, mint hogy még a saját kapcsolati hálómat is bevetettem, hogy a helyen legyen egy normális DJ pult, és meggyőztem Jencit, aki a hazai hangtechnika-bérlés egyik legjobban felszerelt cégét viszi, hogy mivel én ott vagyok, bízzon meg egy olyan új piaci szereplőben, akivel még sosem dolgozott együtt korábban. Itt én abszolút a Jenci és köztem évek alatt felépített jó munkakapcsolatomat és megbízhatóságomat vittem vásárra, és miután én már nem voltam ott a MOF-ban, kötelességemnek éreztem erről is tájékoztatni, de hozzátettem, hogy attól, mert én nem vállalok felelősséget az oda bérbe adott holmikért, biztos vagyok benne, hogy Vadre mindig korrektül fogja kezelni a helyzetet – egyáltalán nem kívántam Vadrénak rosszat ez ügyben.</p>
  
  <h3>Ha előttünk hirdette meg más a buliját, akkor mi miért nem raktuk arrébb a mi bulinkat?</h3>
  <p>Nos, ez nem ilyen egyszerű. A COVID előtti időkben se lett volna az, de 2021-re az az állapot állt be, hogy abból dolgozol, amit kapsz. Nekünk május 12 jutott abban a hónapban, átszervezésre esélyünk sem volt, csak a lemondás maradt volna opcióként.</p>
  
  <h3>Miért nem vetettem fel a problémáimat időben?</h3>
  <p>Független attól, hogy a problémáim és igényeim egy részét igenis jeleztem korábban is, ez abszolút jogos, megtehettem volna. De mivel nem csak üzleti, hanem baráti viszony is volt, ezért egy kicsit kényesebben és óvatosan álltam ezekhez a kérdésekhez hozzá én is. Kár volt, tanultam ebből is.</p>

  <h3>Na de mi ez a cirkusz?</h3>
  <p>Április vége óta próbálom egyébként megfejteni azt, hogy ez az egész miért így csapódott le nálam, és ugyan még nem teljesen tiszta a kép, de valószínűleg nem így alakulnak a dolgok, ha Vadréval egyébként nem ápoltunk volna közeli baráti viszonyt. Megannyi közös élményünk van az elmúlt 4-5 évből, és nem egyszer tűzbe tettem érte a kezem. Talán pont ezért is esik ennyire szarul, ahogy az egész szituációt kezelte az elejétől fogva.</p>

  <h3>Mit akarok kihozni ebből az egészből?</h3>
  <p>Én egyébként a Pride előtti üzenetváltás után lezártnak tekintem Vadréval az ügyet, nem szeretnék se vele, se a MOF-fal foglalkozni, és ezt tartom is. Részemről van ennél rengeteg jobb dolgom is, aminek többek között a Kinksterre járók, vagy épp a zenémet kedvelők fognak örülni remélhetőleg hamarosan.</p> 
  
  <p>Ezért is született meg ez az összefoglaló. Én itt ezt a dolgot lezártam.</p>

  <p>A Kinkster megy tovább, eddig is egy önálló szervezeti egység voltunk, önálló célokkal, ezen ez az egész cirkusz nem változtat semmit. Én nem várom el senkitől, hogy bárki mellett is pálcát törjön, eddig sem sértődtem meg senkire, ha a MOF-os/Kink-es/akármilyen élményeiről mesélt, ezek után se fogok.</p>

  <p>Azt is fontos látni, hogy ugyan a Kinkster egyik főszervezője én vagyok, de attól még a Kinkster egy szervező csapatot jelent, amiben többen működünk közre azért, hogy minél jobb rendezvényeket hozzunk össze hónapról-hónapra. Főszervezőként pedig én azt vallom, hogy egységet csak úgy lehet fenntartani, ha észérvek mentén, és alaposan átbeszélve hozunk döntéseket, próbálva elkerülni az egyéni sérelmeket. Vadre többek között ezért sem lett kitiltva szombat előtt.</p>

  <hr>
  
  <p>Ha van kérdés, észrevétel, vagy bármilyen megjegyzés, ahogy fentebb is jeleztem, szívesen fogadom mindet, annyi az egyetlen egy kérésem, hogy a követhetőség érdekében forduljatok felém privátban.</p>
  
  <p>Csóközön,<br />
  Envo</p>
</div>
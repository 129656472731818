<footer>
        <ul class="">
            <li>
                &copy; 2021 – Zsolt Szrapkó // Envo
            </li>
            <li>
                <a href="https://twitter.com/djenvo">
                    Twitter
                </a>
            </li>
            <li>
                <a href="https://facebook.com/djenvo">
                    Facebook
                </a>
            </li>
            <li>
                <a href="https://instagram.com/djenvo">
                    Instagram
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UC2ndToY6g_FyKIHZJ4Dj1lQ">
                    Youtube
                </a>
            </li>
            <li>
                <a href="https://twitch.tv/djenvo">
                    Twitch
                </a>
            </li>
            <li>
                <a href="https://envo.bandcamp.com">
                    Bandcamp
                </a>
            </li>
        </ul>
</footer>